<template>
 <div class="lg:max-w-7xl lg:mx-auto lg:py-4">
  <div
   class="lg:grid lg:grid-cols-4 lg:gap-8 sm:space-y-0 lg:space-y-0 md:mx-4">
   <div class="col-span-4 flex justify-between mb-4">
    <div class="text-xs font-medium text-gray-700">
     <div class="fixed z-1 left-28">
      <span
       class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-4 h-4 mr-1">
        <path
         d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
       </svg>
       {{ $t("beta") }}
      </span>
     </div>
     <div
      class="fixed z-10"
      @mouseover="showDatePickers = true"
      @mouseleave="showDatePickers = false">
      <div class="flex items-center gap-4">
       <div
        class="flex items-center bg-white shadow-md rounded-md border-gray-400 z-10">
        <p class="border-r px-2 py-2">
         {{
          toLocaleDateString(startDate) +
          (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
         }}
        </p>
        <div
         @click="showDatePickers = true"
         class="px-2 py-2 cursor-pointer bg-gray-400 border-gray-400 text-white hover:bg-blue-400 rounded-r-md">
         <font-awesome-icon icon="calendar" class="flex-shrink-0" />
        </div>
       </div>
      </div>
     
     </div>
    </div>

    <!-- <div class="text-xs font-medium text-gray-700 mr-52">
     <div class="fixed z-10 flex gap-4">
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       Total des appels
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
     </div>
    </div> -->
   </div>
   <!-- stats total calls cards -->
   <div
    class="lg:col-span-4 sm:col-span-1 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-total">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">total des appels</h2>
    </div>
    <div
     class="lg:flex justify-center lg:gap-60 space-y-6 lg:space-y-0"
     v-if="totalCallsArr && totalCallsArrDuration"
     :key="totalCallsArr && totalCallsArrDuration">
     <TotalCallsCard
      :key="totalCallsArr"
      title="Nombre"
      :duration="false"
      :inboundCalls="totalCalls"
      :outboundCalls="totalCallsOutbound"
      :totalCallsArr="totalCallsArr" />
     <TotalCallsCard
      :key="totalCallsArrDuration"
      title="Durée"
      :duration="true"
      :inboundCalls="talking"
      :outboundCalls="talkingOutbound"
      :totalCallsArr="totalCallsArrDuration" />
    </div>
   </div>
   <!-- stats incomming calls cards -->
   <div
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-incomming">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mb-6">
     <h2 class="uppercase text-lg font-bold mb-">appels reçus</h2>
    </div>
    <div class="lg:grid grid-cols-3 lg:space-x-4 space-y-6 lg:space-y-0" v-if="dataQueueTable.length">
     <lost-calls-card
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10" />
     <avg-answer-time-card
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="parseInt(waiting / totalCalls)"
      :talking_10="talking_10" />
     <ten-seconds-calls-card
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10" />
    </div>
    <!-- stats duration total / duration avg / longest call  -->
    <div class="col-span-4 bg-white rounded-lg py-2">
     <div class="flex justify-between"></div>
    </div>
    <div class="lg:grid grid-cols-subgrid col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChart.length &&
       dataColumnChart[0] &&
       dataColumnChart[0].data &&
       dataColumnChart[0].labels &&
       dataColumnChartHour.length &&
       dataColumnChartHour[0] &&
       dataColumnChartHour[0].data &&
       dataColumnChartHour[0].labels
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHour[0]['data']
        : dataColumnChart[0]['data']
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHour[0]['labels']
        : dataColumnChart[0]['labels']
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChart[0]['data'])"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChart[0]['data'])"
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="lg:grid lg:space-y-0 sm:space-y-8 grid-cols-4 lg:gap-2 text-sm">
     <div
      v-if="talking && totalCalls"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels reçus est de
         <span class="">
          {{
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[0])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[1])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ lowestRateHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ lowestRateHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ lowestRateHour.split(":")[1] }}
          des appels ne sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ bestRateHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class=""> {{ bestRateHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ bestRateHour.split(":")[1] }}
          des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ mostCallsHour.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class=""> {{ mostCallsHour.split(":")[0].split("-")[1] }} </span
          >,
          {{ mostCallsHour.split(":")[1] }}
          ont été reçus .
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre 9h et 18h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats outgoing calls cards -->
   <div
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-outgoing">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">appels émis</h2>
    </div>
    <div class="col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChartOutbound.length &&
       dataColumnChartOutbound[0] &&
       dataColumnChartOutbound[0].data &&
       dataColumnChartOutbound[0].labels &&
       dataColumnChartHourOutbound.length &&
       dataColumnChartHourOutbound[0] &&
       dataColumnChartHourOutbound[0].data &&
       dataColumnChartHourOutbound[0].labels
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourOutbound[0]['data']
        : dataColumnChartOutbound[0]['data']
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourOutbound[0]['labels']
        : dataColumnChartOutbound[0]['labels']
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChartOutbound[0]['data'])"
      :totalUnanswered="
       this.getUnansweredNumb(dataColumnChartOutbound[0]['data'])
      "
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="lg:grid grid-cols-4 gap-2 text-sm">
     <div
      v-if="talkingOutbound && totalCallsOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels émis est de
         <span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ lowestRateHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ lowestRateHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ lowestRateHourOutbound.split(":")[1] }}
          des appels ne sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ bestRateHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ bestRateHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ bestRateHourOutbound.split(":")[1] }}
          des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class="">
           {{ mostCallsHourOutbound.split(":")[0].split("-")[0] }}
          </span>
          et
          <span class="">
           {{ mostCallsHourOutbound.split(":")[0].split("-")[1] }} </span
          >,
          {{ mostCallsHourOutbound.split(":")[1] }}
          ont été émis.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre 9h et 18h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats top 10 users -->
   <div
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm"
    id="stats-top">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 class="uppercase text-lg font-bold">Top 10 utilistateurs</h2>
    </div>
    <!-- <div
     class="px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 -mb-4">
     Voici un aperçu des dix
     <span class="font-medium">principaux utilisateurs</span> en fonction du
     nombre d'appels entrants et sortants auxquels ils ont répondu. Les données
     révèlent une répartition diversifiée de l'activité téléphonique parmi ces
     utilisateurs, avec certains occupant des positions plus élevées en raison
     de leur <span class="font-medium">engagement accru dans les appels</span>.
     Ce classement offre un aperçu précieux de l'utilisation des communications
     au sein de notre réseau, mettant en lumière les membres les plus actifs et
     les tendances d'utilisation dominantes.
    </div> -->

    <TopUsersCard
     :key="dataColumnChartTopUsers"
     v-if="
      dataColumnChartTopUsers.length && dataColumnChartTopUsersLabels.length
     "
     @quickPeriod="quickPeriodAnswered = $event"
     :quickPeriod="quickPeriodAnswered"
     @selectPeriod="periodSelectorAnswered = $event"
     :seriesForColumn="dataColumnChartTopUsers"
     :categoriesForColumn="dataColumnChartTopUsersLabels" />
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (1) Appels externes reçus ayant été décrochés par l'utilisateur. Les
      appels non décrochés ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (2) Appels externes émis par l'utilisateur et répondus par l'appelé. Les
      appels non répondus ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (3) Appels internes émis ou reçus par l'utilisateur ayant été répondus.
      Les appels non répondus ne sont pas pris en compte.
     </span>
    </div>
   </div>
   <!-- <div class="col-span-1" v-if="dataQueueTable.length">
    <TopQueuesCard :data="dataQueueTable" />
   </div> -->
  </div>
  
  <!-- <div
   v-if="dataAnswerTime.length && labelAnswerTime.length && dataQueueTable.length > 0 && dataSeriesOrigin.length && dataSeriesOrigin[0] && dataSeriesOrigin[0].data && dataSeriesOriginTop.length && dataSeriesOriginTop[0] && dataSeriesOriginTop[0].data"
   class="lg:flex lg:gap-4 sm:space-y-4 lg:space-y-0"
  >
   <QueueAnswerTimeCard :series="dataAnswerTime" :labels="labelAnswerTime" />
   <OriginsCard :key="dataSeriesOrigin[0]" v-show="dataSeriesOrigin[0].data" :data="dataSeriesOrigin[0].data" @quickPeriod="quickPeriodOrigin = $event" :quickPeriod="quickPeriodOrigin" @selectPeriod="periodSelectorOrigin = $event" />
   <OriginsTopCard :key="dataSeriesOriginTop[0]" v-show="dataSeriesOriginTop[0].data" :data="dataSeriesOriginTop[0].data" @quickPeriod="quickPeriodOriginTop = $event" :quickPeriod="quickPeriodOriginTop" @selectPeriod="periodSelectorOriginTop = $event" />
  </div> -->
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";
import TopUsersCard from "../../components/PBX/Dashboard/TopUsersCard.vue";
import TenSecondsCallsCard from "../../components/PBX/Dashboard/TenSecondsCallsCard.vue";
import TotalCallsCard from "../../components/PBX/Dashboard/TotalCallsCard.vue";
import QueueAnswerTimeCard from "../../components/PBX/Dashboard/QueueAnswerTimeCard.vue";
import TopQueuesCard from "../../components/PBX/Dashboard/TopQueuesCard.vue";
import AnsweredCard from "../../components/PBX/Dashboard/AnsweredCard.vue";
import OriginsCard from "../../components/PBX/Dashboard/OriginsCard.vue";
import OriginsTopCard from "../../components/PBX/Dashboard/OriginsTopCard.vue";
import AvgAnswerTimeCard from "../../components/PBX/Dashboard/AvgAnswerTimeCard.vue";
import LostCallsCard from "../../components/PBX/Dashboard/LostCallsCard.vue";

const elementTypes = {
 0: "extension",
 1: "world",
 2: "undefined (2)",
 4: "queue",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "external_number_no_success",
 14: "call_flow",
 999: "not provided",
};

const calendarOptions = [
 {
  name: "day",
  value: "day",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: ["presentationKey"],
 components: {
  EmptyStateDashboard,
  AnsweredCard,
  OriginsCard,
  OriginsTopCard,
  TopQueuesCard,
  QueueAnswerTimeCard,
  TotalCallsCard,
  AvgAnswerTimeCard,
  LostCallsCard,
  TenSecondsCallsCard,
  TopUsersCard,
 },
 data() {
  return {
   totalCallsInboundPbx: 0,
   totalCallsInternalPbx: 0,
   totalCallsOutboundPbx: 0,
   talkingInboundPbx: 0,
   talkingInternalPbx: 0,
   talkingOutboundPbx: 0,
   totalCallsArrPbx: [0, 0, 0],
   totalCallsArrDurationPbx: [0, 0, 0],
   customDate: null,
   showDatePickers: false,
   period: "",
   calendarOptions,
   selectedPeriod: "",
   answerType: "hour",
   elementTypes,
   quickPeriodAnswered: "last 30 days",
   quickPeriodOrigin: "last 30 days",
   quickPeriodOriginTop: "last 30 days",
   defaultPeriod: 30,
   periodSelectorAnswered: null,
   periodSelectorOrigin: null,
   periodSelectorOriginTop: null,
   dataColumnChart: [],
   dataColumnChartOutbound: [],
   dataColumnChartHour: [],
   dataColumnChartHourOutbound: [],
   dataSeriesOrigin: [],
   dataSeriesOriginTop: [],
   dataColumnChartTopUsers: [],
   dataColumnChartTopUsersLabels: [],
   dataQueueTable: [],
   dataAnswerTime: [],
   labelAnswerTime: [],
   data: undefined,
   totalCalls: 0,
   totalCallsOutbound: 0,
   lostCalls: 0,
   lostCallsOutbound: 0,
   answered_15: 0,
   talking_10: 0,
   waiting: 0,
   waitingOutbound: 0,
   talkingOutbound: 0,
   talking: 0,
   answering: 0,
   answeringOutbound: 0,
   mostCallsHour: "",
   mostCallsHourOutbound: "",
   bestRateHour: "",
   bestRateHourOutbound: "",
   lowestDisuadedRateHour: "",
   lowestAbandonedRateHour: "",
   lowestRateHour: "",
   lowestRateHourOutbound: "",
   totalCallsArr: [0, 0],
   totalCallsArrDuration: [0, 0],
   startDate: null,
   endDate: null,
   periodSelector: undefined,
   timeSelected: [],
   isAllSelected: "",
  };
 },
 methods: {
  async getPbxData(key) {
   try {
    const res = await axios.get(
     `https://xp-stats-101.bluerock.tel/api/v1.2/reports/${key}`
    );
    console.log(res);

    return res.data;
   } catch (error) {
    console.log("getPbxData ~ error:", error);
    this.errorHandling(error);
    this.$emit("error404", error.response);
   }
  },
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  setPeriod(key) {
   this.$emit("setLoading", true);
   this.totalCalls = null;
   this.totalCallsOutbound = null;
   this.lostCalls = null;
   this.lostCallsOutbound = null;
   this.answered_15 = null;
   this.talking_10 = null;
   this.waiting = null;
   this.waitingOutbound = null;
   this.talkingOutbound = null;
   this.talking = null;
   this.answering = null;
   this.answeringOutbound = null;
   this.mostCallsHour = null;
   this.mostCallsHourOutbound = null;
   this.bestRateHour = null;
   this.bestRateHourOutbound = null;
   this.lowestDisuadedRateHour = null;
   this.lowestAbandonedRateHour = null;
   this.lowestRateHour = null;
   this.lowestRateHourOutbound = null;
   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   this.dataColumnChart = [];
   this.dataColumnChartOutbound = [];
   this.dataColumnChartHour = [];
   this.dataColumnChartHourOutbound = [];
   this.dataSeriesOrigin = [];
   this.dataSeriesOriginTop = [];
   this.dataColumnChartTopUsers = [];
   this.dataColumnChartTopUsersLabels = [];
   this.dataQueueTable = [];
   this.dataAnswerTime = [];
   this.labelAnswerTime = [];

   this.getDataFromElementForColumn(key)
    .then(() =>
     this.getTopUsers(key)
      .then(() => this.getDataTopQueues(key))
      .then(() => this.getDataFromElementForColumnOutbound(key))
    )
    .finally(() => this.$emit("setLoading", false));
  },
  async getDataFromElementForColumnOutbound(key) {
   let res = undefined;

   this.dataColumnChartOutbound = [];

   let obj = {};
   let dataObjAns = {};
   let dataObjUn = {};

   let resReport = await this.getPbxData(key);

   let parameters = resReport.parameters;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   res = resReport.outbound;

   if (res) {
    this.setAnsweredUnansweredOutboundByHours(res);
    this.setOutboundTotalValues(res);

    let keys = [];

    keys = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     obj = {};
     dataObjUn = {};
     dataObjAns = {};
     dataObjAns["name"] = "Répondus";
     dataObjAns["data"] = [];
     dataObjUn["name"] = "Non répondus";
     dataObjUn["data"] = [];
     obj["data"] = [];
     obj["labels"] = [];

     let filledArrayDataAns = new Array(days.length).fill(0);
     let filledArrayDataUns = new Array(days.length).fill(0);

     keys.forEach((element, index) => {
      let splitElement = element.split(":");
      let requestDay = splitElement[0];
      if (splitElement[2] === "*" && splitElement[3] === "calls") {
       if (splitElement[4] === "answered") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataAns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) <=
            parseInt(res["daily*"][element.replace("answered", "calls")])
            ? parseInt(res["daily*"][element])
            : parseInt(res["daily*"][element.replace("answered", "calls")])
          );
         }
        }
        dataObjAns["name"] = "Répondus";
        dataObjAns["data"] = filledArrayDataAns;
       }

       if (splitElement[4] === "calls") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataUns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) -
            (parseInt(
             res["daily*"][element.replace("calls:calls", "calls:answered")]
            )
             ? parseInt(
                res["daily*"][element.replace("calls:calls", "calls:answered")]
               ) <= parseInt(res["daily*"][element])
               ? parseInt(
                  res["daily*"][
                   element.replace("calls:calls", "calls:answered")
                  ]
                 )
               : parseInt(res["daily*"][element])
             : 0)
          );
         }
        }
        dataObjUn["name"] = "Non répondus";
        dataObjUn["data"] = filledArrayDataUns;
       }
      } else {
       // in case there is key but no data for element
      }
     });

     obj["labels"] = days;

     obj["data"].splice(0, 1, dataObjAns);
     obj["data"].splice(1, 1, dataObjUn);

     this.dataColumnChartOutbound[0] = obj;
    } else {
     obj = {};

     obj["labels"] = [];
     obj["data"] = [];

     this.dataColumnChartOutbound[0] = obj;
    }
   }
  },
  setOutboundTotalValues(res) {
   this.totalCallsOutbound = null;
   this.lostCallsOutbound = null;
   this.waitingOutbound = null;
   this.talkingOutbound = null;
   this.answeringOutbound = null;
   let arr = [];
   let data = res["sum_hourly*"];
   let objCalls = {};
   let keys = Object.keys(data);
   keys.forEach(key => {
    let splitKey = key.split(":");
    let keyType = splitKey[3];
    let keyAction = splitKey[4];
    let keyHourly = splitKey[0].slice(-2);
    if (parseInt(keyHourly) >= 0) {
     if (keyType == "calls") {
      if (keyAction === "calls") {
       if (objCalls[`${"calls_" + keyHourly}`]) {
        objCalls[`${"calls_" + keyHourly}`] =
         parseInt(objCalls[`${"calls_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"calls_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "answered") {
       if (objCalls[`${"answered_" + keyHourly}`]) {
        objCalls[`${"answered_" + keyHourly}`] =
         parseInt(objCalls[`${"answered_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"answered_" + keyHourly}`] = parseInt(data[key]);
       }
      }
     }
     if (keyType == "duration") {
      if (keyAction === "answering") {
       if (objCalls[`${"answering_" + keyHourly}`]) {
        objCalls[`${"answering_" + keyHourly}`] =
         parseInt(objCalls[`${"answering_" + keyHourly}`]) +
         parseInt(data[key]);
       } else {
        objCalls[`${"answering_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "talking") {
       if (objCalls[`${"talking_" + keyHourly}`]) {
        objCalls[`${"talking_" + keyHourly}`] =
         parseInt(objCalls[`${"talking_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"talking_" + keyHourly}`] = parseInt(data[key]);
       }
      }
      if (keyAction === "waiting") {
       if (objCalls[`${"waiting_" + keyHourly}`]) {
        objCalls[`${"waiting_" + keyHourly}`] =
         parseInt(objCalls[`${"waiting_" + keyHourly}`]) + parseInt(data[key]);
       } else {
        objCalls[`${"waiting_" + keyHourly}`] = parseInt(data[key]);
       }
      }
     }
    }
   });
   let value = null;
   let rate = null;
   let disuadedRate = 1;
   let abandonedRate = 1;
   let lowestRate = 1;
   let totalCalls = 0;
   let totalAnswered = 0;
   let totalTalking = 0;
   let totalWaiting = 0;
   let totalAnswering = 0;
   Object.keys(objCalls).forEach(key => {
    let time = key.split("_")[1];
    if (key.includes("talking")) {
     totalTalking = totalTalking + parseInt(objCalls[key]);
    }
    if (key.includes("waiting")) {
     totalWaiting = totalWaiting + parseInt(objCalls[key]);
    }
    if (key.includes("answering")) {
     totalAnswering = totalAnswering + parseInt(objCalls[key]);
    }
    if (key.includes("calls")) {
     totalCalls = totalCalls + parseInt(objCalls[key]);
     if (time <= 17 && time >= 9 && parseInt(objCalls[key]) > value) {
      value = parseInt(objCalls[key]);
      this.mostCallsHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${value} appels`;
     }
    }
    if (key.includes("answered")) {
     totalAnswered = totalAnswered + parseInt(objCalls[key]);
     if (
      time <= 17 &&
      time >= 9 &&
      parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) > rate
     ) {
      rate =
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <= 1
        ? parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`])
        : 1;
      this.bestRateHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${(rate * 100).toFixed(0)}%`;
     }
     if (
      time <= 17 &&
      time >= 9 &&
      parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) < lowestRate
     ) {
      lowestRate =
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
      this.lowestRateHourOutbound =
       parseInt(time) +
       "h" +
       "-" +
       (parseInt(time) + 1) +
       "h" +
       ":" +
       `${(100 - lowestRate * 100).toFixed(0)}%`;
     }
    }
   });
   this.totalCallsArr[1] = totalCalls;
   this.totalCallsArrDuration[1] = totalTalking;
   this.totalCallsOutbound = totalCalls;
   this.lostCallsOutbound = totalCalls - totalAnswered;
   this.waitingOutbound = totalWaiting;
   this.talkingOutbound = totalTalking;
   this.answeringOutbound = totalAnswering;
  },
  setAnsweredUnansweredOutboundByHours(res) {
   this.dataColumnChartHourOutbound = [];
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   let data = res["sum_hourly*"];
   let keys = Object.keys(data);
   let labels = [];
   let filledArrayDataAns = [];
   let filledArrayDataUns = [];
   keys.forEach(element => {
    let key = element.split(":");
    let time = key[0].slice(-2);
    if (parseInt(time) >= 0 && labels.indexOf(time) < 0) {
     labels.push(time);
    }
   });
   let sortedLabels = labels.sort((a, b) => parseInt(a) - parseInt(b));
   sortedLabels.forEach((label, index) => {
    keys.forEach(element => {
     let key = element.split(":");
     let time = key[0].slice(-2);
     let dataType = key[3];
     let action = key[4];
     if (parseInt(time) == parseInt(label)) {
      if (dataType === "calls") {
       if (action === "answered") {
        filledArrayDataAns.splice(index, 0, parseInt(data[element]));
        filledArrayDataUns.splice(
         index,
         0,
         parseInt(data[element.replace("answered", "calls")]) -
          parseInt(data[element]) >=
          0
          ? parseInt(data[element.replace("answered", "calls")]) -
             parseInt(data[element])
          : 0
        );
       }
      }
     }
    });
   });
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = sortedLabels;
   //  let filledArrayDataAns = new Array(labels.length).fill(0);
   //  let filledArrayDataUns = new Array(labels.length).fill(0);
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHourOutbound[0] = obj;
  },
  setTopUsers(data) {
   const users = data?.users;
   let sortedUsers = [];

   if (Object.keys(users).length > 0) {
    sortedUsers = Object.entries(users)
     .map(([id, user]) => {
      const sum_answered =
       user.u_outside_calls_made_answered +
       user.u_internal_calls_made_answered +
       user.u_outside_calls_solicited_answered +
       user.u_internal_calls_solicited_answered;
      const sum_total =
       user.u_outside_calls_made +
       user.u_internal_calls_made +
       user.u_outside_calls_solicited +
       user.u_internal_calls_solicited;
      return { id, sum_total, sum_answered, ...user };
     })
     .sort((a, b) => b.sum_answered - a.sum_answered)
     .map(({ id, ...user }) => ({ id, ...user }));
   }
   console.log(sortedUsers);
   sortedUsers = sortedUsers.slice(0, 10);
   const labels = sortedUsers.map(user => user.name);

   const series = [
    {
     name: "Reçus&sup1;",
     data: sortedUsers.map(user => user.u_outside_calls_solicited_answered),
    },
    {
     name: "Emis&sup2;",
     data: sortedUsers.map(user => user.u_outside_calls_made_answered),
    },
    {
     name: "Internes&sup3;",
     data: sortedUsers.map(
      user =>
       user.u_internal_calls_made_answered +
       user.u_internal_calls_solicited_answered
     ),
    },
    // {
    //  name: "u_internal_calls_solicited_answered",
    //  data: sortedUsers.map(user => user.u_internal_calls_solicited_answered),
    // },
   ];
   this.dataColumnChartTopUsers = series;
   this.dataColumnChartTopUsersLabels = labels;
  },
  setPbxData(data) {
   this.totalCallsInboundPbx = data?.pbx?.c_solicited_calls;
   this.totalCallsInternalPbx = data?.pbx?.c_solicited_internal_calls;
   this.totalCallsOutboundPbx = data?.pbx?.c_solicited_outbound_calls;
   this.talkingInboundPbx = data?.pbx?.c_duration;
   this.talkingInternalPbx = data?.pbx?.c_duration_internal;
   this.talkingOutboundPbx = data?.pbx?.c_duration_outbound;
   this.totalCallsArrPbx = [
    this.totalCallsInboundPbx,
    this.totalCallsOutboundPbx,
    this.totalCallsInternalPbx,
   ];
   this.totalCallsArrDurationPbx = [
    this.talkingInboundPbx,
    this.talkingOutboundPbx,
    this.talkingInternalPbx,
   ];
  },
  async getTopUsers(key) {
   let res = undefined;
   let resReport = await this.getPbxData(key);
   console.log("getTopUsers ~ resReport:", resReport);

   let parameters = resReport.parameters;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   res = resReport.pbx;
   console.log("getTopUsers ~ res:", res);

   this.setTopUsers(res?.data);
   this.setPbxData(res?.data);
  },
  async getDataFromElementForColumn(key) {
   let res = undefined;

   this.dataColumnChart = [];

   let obj = {};
   let dataObjAns = {};
   let dataObjUn = {};

   let resReport = await this.getPbxData(key);

   let parameters = resReport.parameters;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";
   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   this.startDate = parameters.start;
   this.endDate = parameters.end;

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   res = resReport.answeredQueue;

   if (res) {
    console.log("getDataFromElementForColumn ~ res:", res);

    let keys = [];

    keys = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     obj = {};
     dataObjUn = {};
     dataObjAns = {};
     dataObjAns["name"] = "Répondus";
     dataObjAns["data"] = [];
     dataObjUn["name"] = "Non répondus";
     dataObjUn["data"] = [];
     obj["data"] = [];
     obj["labels"] = [];

     let filledArrayDataAns = new Array(days.length).fill(0);
     let filledArrayDataUns = new Array(days.length).fill(0);

     keys.forEach((element, index) => {
      let splitElement = element.split(":");
      let requestDay = splitElement[0];
      if (splitElement[2] === "*") {
       if (splitElement[3] === "answered") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataAns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) <=
            parseInt(res["daily*"][element.replace("answered", "calls")])
            ? parseInt(res["daily*"][element])
            : parseInt(res["daily*"][element.replace("answered", "calls")])
          );
         }
        }
        dataObjAns["name"] = "Répondus";
        dataObjAns["data"] = filledArrayDataAns;
       }

       if (splitElement[3] === "calls") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataUns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) -
            (parseInt(res["daily*"][element.replace("calls", "answered")])
             ? parseInt(res["daily*"][element.replace("calls", "answered")]) <=
               parseInt(res["daily*"][element])
               ? parseInt(res["daily*"][element.replace("calls", "answered")])
               : parseInt(res["daily*"][element])
             : 0)
          );
         }
        }
        dataObjUn["name"] = "Non répondus";
        dataObjUn["data"] = filledArrayDataUns;
       }
      } else {
       // in case there is key but no data for element
      }
     });

     obj["labels"] = days;

     obj["data"].splice(0, 1, dataObjAns);
     obj["data"].splice(1, 1, dataObjUn);

     this.dataColumnChart[0] = obj;
    } else {
     obj = {};

     obj["labels"] = [];
     obj["data"] = [];

     this.dataColumnChart[0] = obj;
    }
   }
   this.totalCalls =
    this.getAnsweredNumb(this.dataColumnChart[0]["data"]) +
    this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
   this.lostCalls = this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
  },
  async getDataTopQueues(key) {
   let arr = [];
   let res = undefined;
   let resReport = await this.getPbxData(key);

   let parameters = resReport.parameters;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   res = resReport.report;
   if (res) {
    console.log("file: Dashboard.vue:312 ~ getDataTopQueues ~ res:", res);

    let keys = Object.keys(res["daily*"]);
    let keysHourly = Object.keys(res["sum_hourly*"]);
    this.getQueueAnswerTime(res);

    let objCalls = {};

    keysHourly.forEach(keyHour => {
     let splitKeyHour = keyHour.split(":");
     let keyDn = splitKeyHour[4];
     let keyType = splitKeyHour[3];
     let keyHourly = splitKeyHour[0].slice(-2);
     let keyAction = splitKeyHour[5];
     if (keyType == 4 && parseInt(keyHourly) >= 0) {
      if (keyAction === "calls") {
       if (objCalls[`${"calls_" + keyHourly}`]) {
        objCalls[`${"calls_" + keyHourly}`] =
         parseInt(objCalls[`${"calls_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"calls_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "answered") {
       if (objCalls[`${"answered_" + keyHourly}`]) {
        objCalls[`${"answered_" + keyHourly}`] =
         parseInt(objCalls[`${"answered_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"answered_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "disuaded") {
       if (objCalls[`${"disuaded_" + keyHourly}`]) {
        objCalls[`${"disuaded_" + keyHourly}`] =
         parseInt(objCalls[`${"disuaded_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"disuaded_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
      if (keyAction === "abandoned") {
       if (objCalls[`${"abandoned_" + keyHourly}`]) {
        objCalls[`${"abandoned_" + keyHourly}`] =
         parseInt(objCalls[`${"abandoned_" + keyHourly}`]) +
         parseInt(res["sum_hourly*"][keyHour]);
       } else {
        objCalls[`${"abandoned_" + keyHourly}`] = parseInt(
         res["sum_hourly*"][keyHour]
        );
       }
      }
     }
    });

    let value = null;
    let rate = 0;
    let disuadedRate = 1;
    let abandonedRate = 1;
    let lowestRate = 1;
    let labels = [];
    Object.keys(objCalls).forEach(key => {
     let time = key.split("_")[1];
     if (key.includes("calls")) {
      labels.push(time);
      if (parseInt(objCalls[key]) > value) {
       value = parseInt(objCalls[key]);
       this.mostCallsHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${value} appels`;
      }
     }
     if (key.includes("answered")) {
      if (
       time <= 17 &&
       time >= 9 &&
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) > rate
      ) {
       rate = parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.bestRateHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${(rate * 100).toFixed(0)}%`;
      }
      if (
       time <= 17 &&
       time >= 9 &&
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
        lowestRate
      ) {
       lowestRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestRateHour =
        parseInt(time) +
        "h" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        ":" +
        `${(100 - lowestRate * 100).toFixed(0)}%`;
      }
     }
     if (key.includes("disuaded")) {
      if (
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
       disuadedRate
      ) {
       disuadedRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestDisuadedRateHour =
        parseInt(time) +
        "h" +
        "00" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        "00" +
        ":" +
        ` (${(disuadedRate * 100).toFixed(0)}%)`;
      }
     }
     if (key.includes("abandoned")) {
      if (
       parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]) <
       abandonedRate
      ) {
       abandonedRate =
        parseInt(objCalls[key]) / parseInt(objCalls[`calls_${time}`]);
       this.lowestAbandonedRateHour =
        parseInt(time) +
        "h" +
        "00" +
        "-" +
        (parseInt(time) + 1) +
        "h" +
        "00" +
        ` (${(abandonedRate * 100).toFixed(0)}%)`;
      }
     }
    });
    let sortedLabels = labels.sort((a, b) => parseInt(a) - parseInt(b));
    this.setAnsweredUnansweredByHour(sortedLabels, objCalls);

    let calls = 0;
    let answered = 0;
    let disuaded = 0;
    let abandoned = 0;
    let answered_15 = 0;
    let talking_10 = 0;
    let waiting = 0;
    let talking = 0;
    let answering = 0;

    // let answering = 0;
    let obj = {};
    keys.forEach(key => {
     let splitKey = key.split(":");
     let keyDn = splitKey[4];
     let keyType = splitKey[3];

     if (keyType == 4) {
      if (splitKey[6] === "calls") {
       calls = parseInt(calls) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "answered") {
       answered = parseInt(answered) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "disuaded") {
       disuaded = parseInt(disuaded) + parseInt(res["daily*"][key]);
      }
      if (splitKey[6] === "abandoned") {
       abandoned = parseInt(abandoned) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("answering") &&
       splitKey[6].includes("_")
      ) {
       if (
        parseInt(
         splitKey[6].split("_")[2]
          ? splitKey[6].split("_")[2]
          : splitKey[6].split("_")[1]
        ) <= 15
       ) {
        answered_15 = parseInt(answered_15) + parseInt(res["daily*"][key]);
       }
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("talking") &&
       splitKey[6].includes("_")
      ) {
       if (
        parseInt(
         splitKey[6].split("_")[2]
          ? splitKey[6].split("_")[2]
          : splitKey[6].split("_")[1]
        ) <= 10
       ) {
        talking_10 = parseInt(talking_10) + parseInt(res["daily*"][key]);
       }
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("waiting") &&
       !splitKey[6].includes("_")
      ) {
       waiting = parseInt(waiting) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("talking") &&
       !splitKey[6].includes("_")
      ) {
       talking = parseInt(talking) + parseInt(res["daily*"][key]);
      }
      if (
       splitKey[5] === "duration" &&
       splitKey[6].includes("answering") &&
       !splitKey[6].includes("_")
      ) {
       answering = parseInt(answering) + parseInt(res["daily*"][key]);
      }
     }
    });

    obj["calls"] = calls;
    obj["answered"] = answered;
    obj["disuaded"] = disuaded;
    obj["abandoned"] = abandoned;
    obj["answered_15"] = answered_15;
    obj["talking_10"] = talking_10;
    obj["waiting"] = waiting;
    obj["talking"] = talking;
    obj["answering"] = answering;
    obj["answered_rate"] = parseInt(((answered / calls) * 100).toFixed(0));
    // obj["answer_time"] = parseInt((answering / calls).toFixed(0));
    arr.push(obj);
    arr = arr.sort((a, b) => {
     return b.calls - a.calls;
    });
    this.dataQueueTable = arr;

    this.answered_15 = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.answered_15);
    }, 0);
    this.talking_10 = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.talking_10);
    }, 0);
    this.waiting = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.waiting);
    }, 0);
    this.talking = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.talking);
    }, 0);
    this.totalCallsArrDuration[0] = this.talking;
    this.answering = arr.reduce((accumulator, object) => {
     return accumulator + parseInt(object.answering);
    }, 0);
    this.totalCallsArr[0] = this.totalCalls;
   }
  },
  setAnsweredUnansweredByHour(labels, data) {
   this.dataColumnChartHour = [];
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = labels;
   let filledArrayDataAns = new Array(labels.length).fill(0);
   let filledArrayDataUns = new Array(labels.length).fill(0);
   labels.forEach((element, index) => {
    if (data[`answered_${element}`]) {
     filledArrayDataAns.splice(index, 1, data[`answered_${element}`]);
    }
    let unanswered =
     (data[`abandoned_${element}`] ? data[`abandoned_${element}`] : 0) +
     (data[`disuaded_${element}`] ? data[`disuaded_${element}`] : 0);
    filledArrayDataUns.splice(index, 1, unanswered);
   });
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHour[0] = obj;
  },
  async getQueueAnswerTime(data) {
   let labels = data.days;
   let keys = Object.keys(data["daily*"]);
   let arr = new Array(labels.length).fill(0);
   let answer_time = 0;
   let calls = 0;
   labels = labels.map(label => this.formatDateApiRequest(label));
   labels.forEach((label, labelIdx) => {
    let idx = null;
    keys.forEach((key, keyIdx) => {
     let splitKey = key.split(":");
     let date = splitKey[0];
     let keyType = splitKey[3];
     if (date == label && keyType == 4) {
      idx = labelIdx;
      if (splitKey[6] === "calls") {
       calls = parseInt(calls) + parseInt(data["daily*"][key]);
      }
      if (splitKey[6] === "answering") {
       answer_time = parseInt(answer_time) + parseInt(data["daily*"][key]);
      }
     }
    });
    arr.splice(idx, 1, parseInt((answer_time / calls).toFixed(0)));
   });
   let removeArr = [];
   arr.forEach((val, idx) => {
    if (val == 0) {
     removeArr.push(idx);
    }
   });
   let count = 0;
   removeArr.forEach(idx => {
    let removeIdx = parseInt(idx) - count;
    arr.splice(removeIdx, 1);
    labels.splice(removeIdx, 1);
    count = count + 1;
   });
   this.dataAnswerTime = arr;
   this.labelAnswerTime = labels;
  },
 },
 mounted() {
  this.setPeriod(this.presentationKey);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "dataSet",
  ]),
 },
 watch: {},
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
